import React, {useEffect} from 'react';
import useScript from '../../hooks/useScript';
import "./style.scss";

const GartnerWidget = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
        if(typeof GartnerPI_Widget !== 'undefined') {
        GartnerPI_Widget({
            size: "small",
            theme: "dark",
            sourcingLink: "",
            widget_id: "ZjE4YjkyNzItNGM2Mi00OGQ0LWIzMjYtNTkxZjIxMzBlM2Iw",
            version: "2",
            container: document.querySelector("#gartner-side-widget")
        })
        }
        `;

        script.async = true;

        setTimeout(() => document.body.appendChild(script), 1000);

        return () => document.body.removeChild(script);
    }, []);

    return (
        <div id="gartner-side-widget" />
    )
}

export default GartnerWidget;