import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Layout from '../components/layout';
import {graphql, Link, navigate} from 'gatsby';
import {CONTACT_FORM_IDS, LANG, DEMO_FORM_ID, IS_DESKTOP, IS_TABLET, IS_SMALL_DESKTOP, IS_MOBILE, DEMO_FORM_IDS, DEMO_STAGING_TEST} from "../common/consts";
import {mapGartner, mapStickyBanner} from '../common/utils';
import MarketoForm from '../components/marketo';
import ContactComplianceImages from '../components/contact-us-compliance-images';
import GartnerWidget from '../components/gartner-widget';
import Tabs from '../components/cato-tabs';
import LogoMarquee from '../components/logo-marquee';
import '../assets/styles/pages/_contact.scss';
import '../assets/styles/responsive/pages/_contact-tablet.scss';
import '../assets/styles/responsive/pages/_contact-mobile.scss';
import '../assets/images/contact/1hUG_4tA.png';

const THANK_YOU_MAP = {
    [LANG.ENG]: '/thank-you/',
    [LANG.FR]: '/fr/thank-you/',
    [LANG.DE]: '/de/thank-you/'
}
const ContactPage = (props) => {


    const {data, location: {hash}} = props;

    const {wpPage} = data;
    const {headlineLoneHeadline, contactLinks, includeGartner, officeLocations, headlineLoneSubheading, buttonsInArowWithTextBelow} = wpPage;

    const [device, setDevice] = useState({
        isMobile: false,
        isDesktop: false,
        isTablet: false,
        isSmallDesktop: false
    });

    useEffect(() => {

        if(IS_MOBILE) {
            setDevice({...device, isMobile: true})
        } else if(IS_DESKTOP){
            setDevice({...device, isDesktop: true})
        } else if(IS_TABLET) {
            setDevice({...device, isTablet: true})
        } else if (IS_SMALL_DESKTOP) {
            setDevice({...device, isSmallDesktop: true})
        }

        // axios({
        //     method: 'get',
        //     url: 'https://www.gartner.com/reviews/public/Widget/js/widget.js',
        //     data: {
        //          size: "small",
        //          theme: "dark",
        //          sourcingLink: "",
        //          widget_id: "ZjE4YjkyNzItNGM2Mi00OGQ0LWIzMjYtNTkxZjIxMzBlM2Iw",
        //          version: "2"
        //     },
        //     headers: {"Access-Control-Allow-Origin": "*"}
        // })
        // .then((response) => {
        //     console.log('response: ', response)
        // });

    },[])


    const genPhoneLink = (item, mobile) => <a href={`tel:${item.link}`}>{!mobile ? "Phone: " : null}{item.link}</a>;

    const genEmailLink = (item, mobile) => <a href={`mailto:${item.link}`}>{item.link}</a>;

    const handleLink = (item, mobile) => {
        const linkText = item.boldText || item.lightText
            ?
            item.lightText && item.lightText.length > 0
                ?
                item.lightText
                :
                item.boldText
            :
            null;

        if (item.linkType === "email") {
            return genEmailLink(item, mobile)
        } else if (item.linkType === "phone") {
            return genPhoneLink(item, mobile)
        }
        else if (item.linkType === "page_link" && item.pageLink) {
            return <Link to={item.pageLink.path} className="partner">{linkText}</Link>
        }

        return <a href={item.link}>{linkText}</a>

    }


    const buttons = buttonsInArowWithTextBelow && buttonsInArowWithTextBelow.buttons ? buttonsInArowWithTextBelow.buttons.map((button, i) => (
        <div key={i} className="button-cont">
            {button.buttonText && <a className={(button.buttonEmphasize ? 'button emphasize' : 'button')} href={button.buttonLink}>{button.buttonText}</a>}
            {button.textUnderButton && device.isMobile && <p className="below-text">{button.textUnderButton}</p>}
        </div>
    )) : null;

    const locations = officeLocations && officeLocations.locations ? officeLocations.locations.map((item, i) => (
        <li className="location" key={`cato-location-${i}`}>
            {item.country && <div className="country">{item.country}</div>}
            {item.address && <div dangerouslySetInnerHTML={{__html: item.address}}/>}
            {item.flag && <img src={item.flag.localFile.publicURL} alt={item.flag.altText} className="flag" />}

        </li>
    )) : null;

    const stickyBanner = mapStickyBanner(wpPage);

    const links = <div className="links">
        {/*{*/}
        {/*contactLinks.contactLinks && contactLinks.contactLinks.map(item => (*/}
        {/*<div>*/}
        {/*{item.type !== 'green_bold' && item.boldText && <span className="green">{item.boldText}</span>}*/}
        {/*{handleLink(item, false)}*/}
        {/*</div>*/}
        {/*))*/}
        {/*}*/}
        {!device.isMobile && buttons &&
        <div className="buttons-in-arow">
            {buttons}
        </div>
        }
        <GartnerWidget/>
        <ContactComplianceImages mobile={device.isMobile} tablet={device.isTablet} />
    </div>

    const gartner = includeGartner ? mapGartner({gartnerType: includeGartner.gartnerType, gartnerInclude: includeGartner.gartnerInclude}) : null;

    const onFormSuccess = () => navigate(THANK_YOU_MAP[props.pageContext.lang in THANK_YOU_MAP ? props.pageContext.lang : LANG.ENG]);

    return (
        <Layout
            className="contact-page"
            whiteMenu={true}
            formPage={true}
            yoastData={wpPage.seo || null}
            seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
            title={wpPage.title}
            lang={props.pageContext.lang}
            transparentMenu={true}
            gartner={gartner}
            seoPath={props.location.pathname}
            breadcrumbs={[{title: wpPage.title, path: props.location.pathname}]}
            translations={props.pageContext.translations}
            wpid={wpPage.databaseId}
            {...{stickyBanner}}>
            <section className="banner-section">
                {headlineLoneHeadline.loneHeadline && <h1>{headlineLoneHeadline.loneHeadline}</h1>}
                {headlineLoneSubheading.loneSubheading && <h2>{headlineLoneSubheading.loneSubheading}</h2>}
                <div className="banner-content-wrapper">
                    {/*<MarketoForm formId={CONTACT_FORM_IDS[props.pageContext.lang]} event={{label: props.location.pathname}}/>*/}
                    {/*<script async src="https://marketo.clearbit.com/assets/v1/marketo/forms.js" data-clearbit-publishable-key="pk_442da54a94d2a2ce5bb02532914c65a4"></script>*/}
                    <Tabs url={hash}>
                        <title id={'contact_us'}>Contact Us</title>
                        <p>
                            <MarketoForm formId={CONTACT_FORM_IDS[props.pageContext.lang]} event={{label: props.location.pathname}}/>

                        </p>
                        <title id={'try'}>Request a Demo</title>
                        <p>
                            <MarketoForm
                                formId={DEMO_FORM_IDS[props.pageContext.lang]}
                                additionalClass="form-demo"
                                event={{label: props.location.pathname}}
                                onFormSuccess={onFormSuccess}
                                includeLoader={true}
                                loader={'light'}
                                withClearbit={true}
                            />
                        </p>
                        <script async src="https://marketo.clearbit.com/assets/v1/marketo/forms.js" data-clearbit-publishable-key="pk_442da54a94d2a2ce5bb02532914c65a4"></script>
                    </Tabs>

                    {links}
                </div>
            </section>

            {/*{contact}*/}

            <div className="customer-logos">
                {<LogoMarquee desktop={device.isDesktop} smallDesktop={device.isSmallDesktop} mobile={device.isMobile}/>}
            </div>

            <section className="locations">
                {device.isMobile && buttons &&
                    <div className="buttons-in-arow">
                        {buttons}
                    </div>
                }
                {officeLocations.locationsSectionHeadline && <h2>{officeLocations.locationsSectionHeadline}</h2>}
                <ul className={'hide-bullets-style'}>
                    {locations}
                </ul>
            </section>
        </Layout>
    )

}

export default ContactPage;

export const pageQuery = graphql`
    query contactPageQuery($id: String!){
        wpPage(id: { eq: $id }) {
            title
            content
            slug
            id
            databaseId
            date(formatString: "MMMM DD, YYYY")
            path: uri
            pageSeoTitle: pageSeoTitle {
                pageSeoTitle
            }
            ...YoastData
            headlineLoneHeadline {
                loneHeadline
            }
            headlineLoneSubheading {
                loneSubheading
            }
            contactLinks {
                contactLinks {
                    type
                    buttonText
                    boldText
                    lightText
                    linkType
                    link
                    pageLink {
                        ... on WpPage {
                            id
                            path: uri
                        }
                    }
                    icon {
                        altText
                        localFile {
                            publicURL
                        }
                    }
                }
            }
            officeLocations {
                locationsSectionHeadline
                locations {
                    country
                    address
                    flag {
                        altText
                        localFile {
                            publicURL
                        }
                    }
                }
            }
            buttonsInArowWithTextBelow {
                buttons {
                    buttonText
                    buttonLink
                    buttonEmphasize
                    textUnderButton
                }
            }
            ...StickyBanner
            ...GartnerStrip
        }
    }
`
