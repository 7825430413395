import React, {useState, useEffect, useRef} from 'react';
import './style.scss';

const Tabs = (props) => {

    const pillRef = useRef(null);

    const {children, url} = props;

    const hash =  url.substring(1)


    const [activeTab, setActiveTab] = useState(0);

    const [slug,setSlug] = useState(hash)

    const starterTab = 0;

    const titles = [];

    const titleId = [];

    const panes = [];

    const dataObject = [];

    const movePill = (starterTab) => {
        let width = 140;
        pillRef.current.style.width = width + 'px';
        pillRef.current.style.left = (starterTab * width) + 'px';

    };

    useEffect(() => {
        let starterTab = 0;

            if(slug === 'try'){
                starterTab = 1;
                setActiveTab(1);
            }else{
                starterTab = 0;
                setActiveTab(0);

            }

            movePill(starterTab);

        return () => {
            // setActiveTab(0)
            // setSlug(null)
            movePill(0);
        }

    },[slug])

    const allTiles = children.map(item => {
        if(item.type === 'title'){
            titles.push(item.props.children);
            titleId.push(item.props.id);
        }
    })

    const changeToActive = (index) => {
        setActiveTab(index)
        setSlug(titleId[index]);
    }

    const allPanes = children.map(item => {
        if(item.type === 'p'){
            panes.push(item.props.children);
        }
    })

    const Data = titles.map((title, index) => {dataObject.push({"title" : title, "id" : titleId[index], "p" : panes[index]})})

    const TabTitles = titles.map((item, index) => {
        return <li key={index}  className={(activeTab === index ? 'active' : 'not-active')} ><a href={`#${titleId[index]}`} onClick={() => {changeToActive(index)}}  >{item}</a></li>
    })

    const TabPanes = panes.map((item, index) => {
        return <div key={index} className={(activeTab === index ? 'show' : 'hide')}>{item}</div>
    })

    return(
        <div className="cato-tabs">
            <div className="tabs">
                <ul>
                    {TabTitles}
                    <li className="pill" ref={pillRef} ></li>
                </ul>
                <div className="panes">
                    {TabPanes}
                </div>

            </div>
        </div>
    )

}

export default Tabs;
