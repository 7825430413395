import React from 'react';
import CatoImg from '../cato-image';
import ISO from './ISO-27001.svg';
import ISOMobile from './ISO-27001M.svg';
import GDPR from './GDPR.svg';
import GDPRMobile from './GDPRM.svg';
import SOC from './SOC2.svg';
import SOCMobile from './AICPAM.svg';
import GARTNER from './Gartner.svg';
import GARTNERMobile from './GARTNERM.svg';
import './style.scss';


const ContactComplianceImages = ({mobile, tablet}) => {
    return <ul className="contact-compliance-images">
        {/*<li>*/}
            {/*<CatoImg*/}
                {/*img={{publicURL: (mobile === true || tablet === true ? ISOMobile : ISO), alt_text: "ISO Compliant"}}*/}
            {/*/>*/}
        {/*</li>*/}
        {/*<li>*/}
            {/*<CatoImg*/}
                {/*img={{publicURL: (mobile === true || tablet === true ? GARTNERMobile : GARTNER), alt_text: "GARTNER Compliant"}}*/}
            {/*/>*/}
        {/*</li>*/}
        {/*<li>*/}
            {/*<CatoImg*/}
                {/*img={{publicURL: (mobile === true || tablet === true ? GDPRMobile : GDPR), alt_text: "GDPR Compliant"}}*/}
            {/*/>*/}
        {/*</li>*/}
        {/*<li>*/}
            {/*<CatoImg*/}
                {/*img={{publicURL: (mobile === true || tablet === true ? SOCMobile : SOC), alt_text: "SOC Compliant"}}*/}
            {/*/>*/}
        {/*</li>*/}

        <li>
            <CatoImg
                img={{publicURL: ISOMobile, alt_text: "ISO Compliant"}}
            />
        </li>
        <li>
            <CatoImg
                img={{publicURL: GARTNERMobile, alt_text: "GARTNER Compliant"}}
            />
        </li>
        <li>
            <CatoImg
                img={{publicURL: GDPRMobile, alt_text: "GDPR Compliant"}}
            />
        </li>
        <li>
            <CatoImg
                img={{publicURL: SOCMobile, alt_text: "SOC Compliant"}}
            />
        </li>
    </ul>
};

export default ContactComplianceImages;