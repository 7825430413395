import React, {useEffect, useState} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import CatoTicker from '../cato-ticker';
import CatoLink from '../cato-link';
import CatoImg from '../cato-image';
import './style.scss';

const LogoMarquee = () => {

    const {isDesktop, isSmallDesktop, isMobile} = useDeviceDetect();

    const [loaded, setLoaded] = useState(false);

    const {
        wpPage: {
            hpVideoBanner: {customerLogos}
        }
    } = useStaticQuery(graphql`
        query LogoMarqueeQuery {
            wpPage(databaseId: {eq: 12}) {
                hpVideoBanner {
                    customerLogos: hp2021CustomerLogos {
                        customerLogo: logo {
                            altText
                            localFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid_withWebp_noBase64
                                    }
                                    original {
                                        width
                                        height
                                    }
                                }
                                publicURL
                            }
                            sourceUrl
                        }
                        link
                    }
                }
            }
        }
    `);

    useEffect(() => {
        if(!loaded) {
            setLoaded(true)
        }

        return () => setLoaded(false);

    }, [])

    const dimension = (dim) => {
        let calcString = `${dim}px / 2`;

        if(isMobile) {
            console.log()
            calcString = `${dim}px * 0.33`;
        } else if (isSmallDesktop) {
            calcString = `${dim}px * 0.33`;
        }

        return `calc(${calcString})`;
    };

    const calcStyle = (img) => ({
        width: dimension(img.childImageSharp.original.width + 2),
        height: dimension(img.childImageSharp.original.height + 2),
        maxHeight: '100%'
    });

    const height = isSmallDesktop ? 100 : isDesktop ? 120 : 75;

    return (
        <div className="customer-logos">
            {loaded && <CatoTicker
                height={height}
                items={customerLogos.map((item, i) => {
                        const img = item.customerLogo.localFile ? <CatoImg img={item.customerLogo.localFile} style={calcStyle(item.customerLogo.localFile)}/> : null;

                        const logo = item.link
                            ?
                            <CatoLink path={item.link} style={{height: '100%'}}>
                                {img}
                            </CatoLink>
                            :
                            img

                        return (
                            <div className="logo-wrapper" key={`partner-logo-${i}`}>
                                {logo}
                            </div>
                        )
                    }
                )}
            />
            }
        </div>
    )
}

export default LogoMarquee;